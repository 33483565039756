<template>
  <v-container>
    <v-skeleton-loader
      boilerplate
      type="card-avatar, article, actions"
    />
  </v-container>
</template>
<script>
  export default {

  }
</script>
